<template>
    <div class='banner'>
        <el-carousel :height="data.height">
            <el-carousel-item v-for="(item, index) in data.banner" :key="index">
                <img :src="item.bannerImgUrl" class="carouse_image_type" alt />
            </el-carousel-item>
        </el-carousel>
        <div v-if="type=='MAIN'" class="text">欢迎使用易起推</div>
    </div>
</template>

<style lang="scss" scoped>
  .banner{
    position: relative;
    .text{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      font-size: 66px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 3px rgba(0,0,0,0.1);
    }
  }
    .carouse_image_type{
      width: 100%;
    }
</style>

<script>
import { defineComponent, reactive, onMounted, getCurrentInstance } from "vue";

export default defineComponent({
    name: "banner",
    props:{
        type:{
            type:String,
            default:""
        },
        height:{
            type:Number,
            default:0
        }
    },
    setup(props) {
        
        let { proxy } = getCurrentInstance();

        const data = reactive({
            height: "",
            banner: [{}]
        });

        const handle_set_size = () => {
            let window = document.documentElement.clientWidth;
            let radio = parseFloat(window / 1920).toFixed(6);
            data.height = radio * props.height + "px";
        };

        onMounted(() => {

            handle_set_size();
            window.onresize = () => {
                handle_set_size();
            };

            proxy.$axios
                .get("/banner/get", {
                    params: {
                        code: props.type
                    }
                })
                .then(res => {
                    data.banner = res.data;
                });
        });

        return {
            data
        };
    }
});
</script>